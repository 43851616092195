import { saveBData } from "./storage";

export function saveUserSort(userId, users, STORAGE_KEY) {
    try {
        const find = users.find(user => user.id == userId)
        if (find) {
            find.count = find.count + 1;
        } else {
            users.push({
                id: userId,
                count: 1
            });
        }
        saveBData(users, STORAGE_KEY);
    } catch (error) {
        console.error(error);
    }

}