
export function getBData(STORAGE_KEY) {
    const storedB = localStorage.getItem(STORAGE_KEY);
    return storedB
        ? JSON.parse(storedB)
        : [];
}


export function saveBData(bData, STORAGE_KEY) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(bData));
}
